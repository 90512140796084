export var funes_pres_calculo = {
    methods: {
        pres_calculo(detalle, ctrls, tipo_ope) {
            console.log("detalle pres_calculo", detalle)
            console.log("ctrls   pres_calculo", ctrls)
            console.log("tipo_op pres_calculo", tipo_ope)
            let COMPRA = tipo_ope == "C" ? true : false;
            let VENTA = tipo_ope == "V" ? true : false;
            console.log("compra/venta  pres_calculo", tipo_ope, COMPRA, VENTA)
            //{id:"0", name:"IVA",  tipos:[{value:"21.0",text:"21%"}, {value:"10.0",text:"10%"},{value:"0.0",text:"0%"},{value:"4.0",text:"4%"}]},
            let rt = this.$store.state.datos_iniciales.rTribut[this.schema.ctrls.rt.value]
            let rtTipos = rt.tipos.map(item => Number(item.value))
            let iva_gral = rtTipos[0];
            let err='';
            // productos flores al 10%
            //let iva10 = [12, 21, 52, 140];
            //let err='';
            //
            var state = {
                imp: 0,
                sup: 0,
                ivapor1: 0,
                ivapor2: 0,
                ivapor3: 0,
                ivapor4: 0,
                ivapor5: 0,
                iva: 0,
                iva1: 0,
                iva2: 0,
                iva3: 0,
                iva4: 0,
                iva5: 0,
                base: 0,
                base1: 0,
                base2: 0,
                base3: 0,
                base4: 0,
                base5: 0,
                pnf1: 0,
                pnf2: 0,
                pnfsuma: 0,
                sup_pd: 0,
                imp_cia: 0,                
                irpf: 0,
                irpfpor: (this.schema.ctrls.irpfpor ? this.schema.ctrls.irpfpor.value : 0),

            }
            
            function round(n, d = 100) {
                n = Number((n * d).toFixed(2));
                n = Math.round(n);
                n = n / 100;
                return n;
           }
            
           // const round = (n, d = 2) => Number(Number(n).toFixed(d));

           //
            function acumula_iva(imp, iva) {
                if (!rtTipos.includes(iva)) err+=iva+"% "
                let key = 0;
                for (let i = 1; i < 6; i++) {
                    if (state["base" + i] == 0 && key === 0) key = i;
                    if (Number(state["ivapor" + i]) == iva) {
                        key = i;
                        break;
                    }
                }
                state["ivapor" + key] = iva;
                state["base" + key] += imp;
                state["iva" + key] = round((state["base" + key] * iva) / 100);
            }

            function suma_totales() {
                state.base = state.base1 + state.base2 + state.base3 + state.base4 + state.base5;
                state.iva = state.iva1 + state.iva2 + state.iva3 + state.iva4 + state.iva5;
                state.irpf=  round((state.base * state.irpfpor) / 100);                
                state.imp = state.base + state.iva + state.sup - state.irpf;
                state.pnfsuma = state.imp - state.sup_pd;
                state.imp_cia += state.imp;
            }

            //
            //-----------------------------------------------------------------------------------------
            //
            //
            //
            // const calculos = (state, item) => {
            const calculos = (state, item) => {

                let imp = Number(item.imp);
                let iva = Number(item.iva);

                //if (item.sup == "N") acumula_iva(imp,iva);
                if (item.sup == "S") {
                    state.sup += imp;

                } else {
                    acumula_iva(imp,iva);
                }


                if (COMPRA) {
                    // "PAGOS DIRECTOS"
                    // Aclarar a los apd que este caso es para derechos de inhumacion y cosas asi
                    // tendria que haber posteriormente factura de proveedor ajeno a la funeraria
                    // tambien se queda como un suplido para la compañia
                    if (item.sup == "S" && item.sp == 1) {
                        state.sup_pd += imp;
                    }

                    // DIFERENCIAS CON LA CIA
                    // algo que factura la funeraria como suplido y no es un pago directo
                    // A la compañia hay que facturarle el IVA 
                    if (item.sup == "S" && item.sp != 1) {
                        state.imp_cia +=round((imp * iva_gral) / 100)
                    }
                }
               //console.log("calculos item,state2", item, state)
                return state;
            };


            // crear array ordenada por iva para los calculos antes de reduce y no machacar detalle
            []
                .concat(detalle)
                .sort((a, b) => a.iva - b.iva)
                .reduce(calculos, state);

            // 
            suma_totales()
            //
            if (VENTA) {
                if (Number(ctrls.tmtpor1.value) != 0) {
                    ctrls.tmt1.value = round(state.imp * Number(ctrls.tmtpor1.value) / 100);
                }
                if (Number(ctrls.tmtpor2.value) != 0) {
                    ctrls.tmt2.value = round(state.imp * Number(ctrls.tmtpor2.value) / 100);
                }
                if (Number(ctrls.tmtpor4.value) != 0) {
                    ctrls.tmt4.value = round(state.imp * Number(ctrls.tmtpor4.value) / 100);
                }

                // Suma tramitaciones
                ctrls.tmt.value =Number(ctrls.tmt1.value) + Number(ctrls.tmt2.value) + Number(ctrls.tmt4.value);
                // Suma APD
                ctrls.tmt.value += Number(ctrls.tmt3.value);
                // Guardo importe sin tramitacion
                ctrls.impstmt.value = state.imp;
                console.log("tramitaciones",ctrls.impstmt,ctrls.tmt,ctrls.tmt1.value , ctrls.tmt2.value ,ctrls.tmt3.value, ctrls.tmt4.value)
                // 
                acumula_iva(ctrls.tmt.value, iva_gral);
                suma_totales()
            }

            // paso calculos a schema.ctrls
            Object.keys(state).forEach(key => {
                if (Object.prototype.hasOwnProperty.call(this.schema.ctrls, key)) this.schema.ctrls[key].value = state[key];
            });
            if (err!='')     this.$root.$alert.open("Tipos "+ rt.name+" no reconocidos:"+err, "error");
            console.log("calculos----------------------------", state);

        },

        //publica
        round(n, d = 100) {
            n = Number((n * d).toFixed(2));
            n = Math.round(n);
            n = n / 100;
            return n;
       }

    }

};
