<!-- Mto Presupuestos Funerarias Sucursales -->

<template>
  <div class="funes_pres_M " v-if="schema">
    <base_Header
      v-if="Entorno.header.header"
      :Entorno="Entorno.header"
      @onEvent="event_Header"
    >
    </base_Header>
    <v-sheet class="contenedor" :elevation="4">
      <!-- Botones Mto -->
      <div class="btras">
        <!-- Mto -->
        <btramto
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="m"
          :estado="estado"
          @onEvent="event_btra_Mto"
        >
        </btramto>

        <!-- Extra -->
        <btraextra
          style="margin-left:100px"
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="standard"
          @onEvent="event_btra_Extra"
        >
        </btraextra>

        <!-- plantillas -->
        <v-select
          style="flex:0 0 240px;white-space: nowrap; margin-left:40px;
                overflow: hidden;
                text-overflow: ellipsis;"
          dense
          @change="changePlantilla()"
          v-model="plantillaValue"
          v-bind="$select"
          label="Plantillas"
          :items="itemsPlantilla"
          :disabled="plantillaDisabled"
          item-value="id"
          item-text="name"
        >
        </v-select>

        <!-- changes presupuesto -->
        <div style="display:flex;margin-left:auto;align-self:flex-end">
          <fun_pres_changes
            :Ctcc="this.schema.ctrls.tcc"
            :casos="[
              'ERROR IMPORTE PRODUCTO',
              'OMISION PRODUCTO',
              'NUEVO PRODUCTO',
              'CAMBIO DE PRODUCTO',
              'MODIFICACION TASAS',
              'NUEVA NEGOCIACION',
              'OTRAS'
            ]"
            :maxchanges="20"
            :maxcoment="25"
            :estado="estado"
            :dialog="dialogChanges"
            @close="dialogChanges = false"
            @open="dialogChanges = true"
          >
            <!-- @close="dialogChanges=false" --> </fun_pres_changes
          >;
        </div>
      </div>

      <!-- Controles del Mto -->
      <div class="conflex" style="width:1190px">
        <!-- dos filas con 2 cada una generales para el formulario -->
        <!-- presupuestos/contratador -->
        <!-- detalle/productos o datos laterales segun is-edithijo -->

        <!-- fila 1  -->
        <div class="fila1 conflex">
          <div class="columna">
            <div class="conflex" style="justify-content:space-between">
              <div class="cab ">DATOS PRESUPUESTO</div>
              <div style="color:steelblue;padding-right:20px">
                {{ accionRow.id }}/{{ funeraria.id + "        " }}
                {{ funeraria.name }}
              </div>
            </div>
            <v-sheet v-bind="$cfg.styles.marco" style="width:750px">
              <div class="conflexnow" style="display:flex">
                <v-select
                  style="flex: 1 1 10%"
                  v-bind="$select"
                  v-model="schema.ctrls.ano.value"
                  :label="schema.ctrls.ano.label"
                  :items="itemsAno"
                  :disabled="!is_edit || app == 2"
                  item-value="id"
                  item-text="name"
                >
                </v-select>
                <v-select
                  style="flex: 1 1 35%"
                  v-bind="$select"
                  v-model="schema.ctrls.id_presciac.value"
                  :label="schema.ctrls.id_presciac.label"
                  :items="itemsPrestacion"
                  :disabled="!is_edit"
                  item-value="id"
                  item-text="name"
                >
                </v-select>

                <v-text-field
                  style="flex: 1 1 55%"
                  v-bind="$input"
                  v-model="schema.ctrls.descrip.value"
                  :label="schema.ctrls.descrip.label"
                  :disabled="!is_edit"
                >
                </v-text-field>
              </div>

              <div class="conflex">
                <div style="flex: 1 1 65%">
                  <ctrlfinder
                    finderName="atlas_F"
                    :schema="schema.ctrls.municipio"
                    :edicion="is_edit"
                  >
                  </ctrlfinder>
                </div>

                <v-select
                  style="flex: 0 0 35%"
                  v-bind="$select"
                  v-model="schema.ctrls.cia_id.value"
                  label="CompaC1C-a"
                  :items="itemsCias"
                  :disabled="!is_edit"
                  item-value="id"
                  item-text="name"
                >
                </v-select>
                <!-- 
                <div style="flex: 0 0 35%">
                  <ctrlfinder
                    finderName="ciac_F"
                    :schema="schema.ctrls.cia_id"
                    :edicion="is_edit"
                  >
                  </ctrlfinder> 
                </div>-->
              </div>

              <div class="conflex">
                <div class="columna">
                  <v-switch
                    style="flex:1 1 15%;margin:0;padding:0"
                    true-value="1"
                    false-value="0"
                    color="green"
                    :readonly="!is_edit || app == 2"
                    v-model="schema.ctrls.preferente.value"
                  ></v-switch>
                  <div style="margin-top:-10px;margin-right:10px">
                    Preferente
                  </div>
                </div>

                <v-select
                  style="text-align:center;flex: 1 1 100px"
                  v-model="schema.ctrls.rt.value"
                  v-bind="$select"
                  label="Tipo I."
                  :items="this.$store.state.datos_iniciales.rTribut"
                  :disabled="!is_edit"
                  item-value="id"
                  item-text="name"
                  @change="changeRt"
                ></v-select>

                <v-select
                  style="flex: 1 1 100px"
                  v-model="schema.ctrls.iva_general.value"
                  v-bind="$select"
                  label="% Gral"
                  :items="get_ivas"
                  :disabled="!is_edit"
                  item-value="value"
                  @change="changeIvaGeneral"
                ></v-select>

                <pres_sumas style="flex:1 1 60%" :pdat="schema.ctrls" />
              </div>
            </v-sheet>
            <!-- fin columna1 -->
          </div>

          <div class="columna">
            <div class="cab">CONTRATADOR</div>

            <v-sheet
              v-bind="$cfg.styles.marco"
              style="width:420px;height:153px"
            >
              <div class="conflex">
                <v-text-field
                  style="flex: 1 1 60%"
                  v-bind="$input"
                  v-model="schema.ctrls.paxfun.value"
                  :label="schema.ctrls.paxfun.label"
                  :disabled="!is_edit"
                >
                </v-text-field>

                <compfecha
                  style="flex: 1 1 30%"
                  :schema="schema.ctrls.fhrev"
                  :edicion="is_edit"
                ></compfecha>
              </div>

              <v-textarea
                rows="3"
                :no-resize="true"
                label="Observaciones"
                v-model="schema.ctrls.obs.value"
                :disabled="!is_edit"
              >
              </v-textarea>
            </v-sheet>
            <!-- fin fila1 columna 2 -->
          </div>
        </div>

        <div class="fila2 conflex">
          <!-- fila2 columna 1-->
          <div class="columna">
            <funes_pres_detalle
              v-if="$store.state[stName].record"
              :padre="stName"
              :id="$store.state[stName].record.id"
              :disparoFinder="disparoFinder"
              @onEvent="event_btra_Mto"
              @onEventOrden="eventOrden"
            >
            </funes_pres_detalle>
          </div>
          <!-- fin fila2 columna 2 ------------------------------->

          <div
            v-show="!is_mxEdit"
            class="columna"
            style="width:430px;padding-left:8px"
          >
            <!-- <v-sheet v-bind="$cfg.styles.marco"> -->
            <div class="conflex">
              <pres_total_compra
                style="flex:0 0 50%"
                :pdat="schema.ctrls"
                :is_edit="is_edit"
              >
              </pres_total_compra>
              <pres_iva_desglose
                style="flex:0 0 50%"
                :pdat="schema.ctrls"
                :is_edit="is_edit"
              >
              </pres_iva_desglose>
            </div>

            <pres_claves :Aimp="clavesAc" :T="clavesTotal" :todo="false">
            </pres_claves>
            <!-- </v-sheet> -->
          </div>
          <!-- fin fila 2 columna 2 -->
        </div>

        <!-- fin bloque controles filas/columnas -->
      </div>
    </v-sheet>
    <!-- ventana modal IMPORTACION PRESUPUESTOS -->

    <v-dialog class="modal" v-model="modal" persistent>
      <div v-if="$store.state[stName].record" class="centrado">
        <component
          :is="componente_dinamico"
          :items-per-page="-1"
          :id="funeraria.id"
          :disparoFinder="disparo_funes_pres_import"
          @onEvent="imp_prestos_extraer"
        >
        </component>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mixinMto } from "@/mixins/mixinMto.js";
import { mixinMD } from "@/mixins/mixinMD.js";
// import { mixinCalculos } from "@/mixins/mixinCalculos.js";
import { funes_pres_calculo } from "@/mixins/funes_pres_calculo.js";
import { calculo_claves } from "@/mixins/calculo_claves.js";
import plugs from "@/common/general_plugs";
const base_Header = () => plugs.groute("base_Header.vue", "base");
const btramto = () => plugs.groute("btramto_new.vue", "comp");
const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
//const btraextra = () => plugs.groute("btraextra.vue", "comp");
const compfecha = () => plugs.groute("compfecha.vue", "comp");
const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
const funes_pres_detalle = () => plugs.groute("funes_pres_detalle.vue", "comp");
const pres_claves = () => plugs.groute("pres_claves.vue", "comp");
const pres_sumas = () => plugs.groute("pres_sumas.vue", "comp");
const pres_iva_desglose = () => plugs.groute("pres_iva_desglose.vue", "comp");
// const pres_total_cia = () => plugs.groute("pres_total_cia.vue", "comp");
const pres_total_compra = () => plugs.groute("pres_total_compra.vue", "comp");
const fun_pres_changes = () => plugs.groute("fun_pres_changes.vue", "comp");
const funes_pres_import_F = () =>
  plugs.groute("funes_pres_import_F.vue", "comp");

export default {
  mixins: [mixinMto, mixinMD, funes_pres_calculo, calculo_claves],
  components: {
    base_Header,
    btramto,
    btraextra,
    compfecha,
    ctrlfinder,
    funes_pres_detalle,
    pres_claves,
    pres_sumas,
    pres_iva_desglose,
    pres_total_compra,
    fun_pres_changes,
    funes_pres_import_F
  },

  props: {
    padre: { type: String, default: "" },
    accion: { type: [Number, String], default: "" },
    accionRow: { type: Object, default: () => {} },
    disparo: { type: Boolean, default: false },
    componenteTipo: { type: String, default: "M" },

    //paso datos de filtro para filtrar los presupuestos que puedo importar
    filtroImportacion: {},
    //
    Entorno: {
      type: Object,
      default: function() {
        return JSON.parse(JSON.stringify(this.$cfg.base.M));
      }
    }
  },

  data() {
    return {
      schema: null,
      api: "funes_pres_M",
      stName: "stMfunes_pres_M",
      stNameHijo: "stFfunes_presLin_F",
      mtos_auxiliares: [
        {
          store: "stMfunes_presLin_M",
          msg:
            "Tiene una linea de producto en ediciC3n. Guarde o Cancele antes de continuar"
        }
      ],
      // variable apertura automC!tica fun_pres_changes
      dialogChanges: false,
      // mtos_auxiliares:[""

      // ]
      mensajeGuardarFin:'',

      // Pendiente: ver donde ponemos campo_relacionado
      campo_relacionado: "fun_id",
      disparoFinder: false,

      // dinC!mico para importaciC3n de presupuestos
      componente_dinamico: null,
      disparo_funes_pres_import: false,
      modal: false,
      //
      // plantillas
      itemsPlantilla: [
        { id: "4", name: "Servicio IncineraciC3n" },
        { id: "3", name: "Servicio Inhumacion" },
        { id: "5", name: "Servicio traslado" },
        { id: "6", name: "Servicio recepciC3n/terminaciC3n" }
      ],
      plantillaValue: "0",
      plantillaDisabled: true,
      //-----
      //producto: null,
      itemsAno: [],
      fila_copiada: null,
      pservicios: null,
      tipo_detalle: "pres",

      lineas_actualizadas: false,
      records_detalle: null,
      schema_detalle: null,
      // Productos con IVA 10% FLORES
      iva10: [12, 21, 52],
      //
      itemsCias: [
        { id: "83", name: "MAPFRE" },
        { id: "50165", name: "KUTXABANK" }
      ],
      itemsPrestacion: [],
      noduplicar_tipos: [10, 30, 18, 38, 36, 16, 17, 37],
      municipio_required: [10, 11, 30, 31, 18, 38, 36, 16, 17, 37],
      apd_tipos: [10, 11, 30, 31, 18, 38],
      apd_cias: [50165, 50508]
    };
  },

  methods: {
    // inicializo hijo antes cargar datos
    before_ini_component() {
      if (!this.$store.state[this.stName]) return;
      if (!this.$store.state[this.stName].hijo) return;
      this.$store.commit(this.stName + "/data2State", {
        prop: "hijo",
        value: this.stNameHijo
      });
    },

    eventOrden(evt) {
      let item = {};
      if (evt.item) item = evt.item;
      if (item == {}) return;
      const hijo = this.$store.state[this.stName].hijo;
      let arrayRecords = JSON.parse(
        JSON.stringify(this.$store.state[hijo].records)
      );
      // si no hay mas de un elemento return
      if (arrayRecords.length < 2) return;

      //encuentro el index del item en la array
      let index = arrayRecords.findIndex(e => e.id == item.id);
      if (index === -1) return;
      item = arrayRecords[index];
      //
      //------down
      if (evt.accion === "down") {
        if (index < arrayRecords.length - 1) {
          arrayRecords[index] = arrayRecords[index + 1];
          arrayRecords[index + 1] = item;
        }
        // vuelta primera posicion
        if (index === arrayRecords.length - 1) {
          arrayRecords.splice(index, 1);
          arrayRecords.splice(0, 0, item);
        }
      }
      //------up
      if (evt.accion == "up") {

        if (index > 0) {
          arrayRecords[index] = arrayRecords[index - 1];
          arrayRecords[index - 1] = item;
        }
        // vuelta ultima posicion
        if (index === 0) {
          arrayRecords.splice(index, 1);
          arrayRecords.splice(arrayRecords.length, 0, item);
        }

      }
      arrayRecords = arrayRecords.map((e, index) => {
        e.orden = index;
        return e;
      });
      this.$store.commit("data2State", {
        modulo: hijo,
        prop: "records",
        value: arrayRecords
      });
      this.detalle_modificado(this.$store.state[hijo]);
    },
    ini_data() {
      // guardo del store en variables locales
      this.schema = this.$store.state[this.stName].schema;

      // configuro Entorno
      this.Entorno.header.titulo = "Mantenimiento Presupuestos Funerarias";
      this.Entorno.header.style += ";min-width:60rem";
      this.Entorno.btra = JSON.parse(
        JSON.stringify(this.$cfg.btra_tipo.standardM)
      );
      this.Entorno.btra.footer.extra = [
        { accion: "imp_prestos", texto: "IMP", show: 1 },
        { accion: "copy", texto: "copy", show: 1 },
        { accion: "pega", texto: "pega", show: 1 },
        {
          accion: "pdf",
          icono: "mdi-file-pdf-outline",
          title: "Ver Pdf",
          show: 1
        }
      ];
      // obtengo items adicionales
      this.itemsPrestacion = this.$store.state.datos_iniciales.tipo_presup;
      this.itemsAno = this.getItemsAno();
    },

    //
    getItemsAno() {
      let ans = [];
      let i = 0;
      let a = new Date().getFullYear() - 1;
      while (i < 3) {
        ans.push({ id: (a + i).toString(), name: a + i });
        i++;
      }

      return ans;
    },

    detalle_cargado() {
      let hijo = this.$store.state[this.stName].hijo;
      this.calculo_claves(this.$store.state[hijo].records);
    },
    record_cargado() {
      // Inicializo tipo de iva
      if (!this.schema.ctrls.rt.value) {
        this.schema.ctrls.rt.value = "0";
        this.schema.ctrls.iva_general.value = this.$store.state.datos_iniciales.rTribut[
          this.schema.ctrls.rt.value
        ].tipos[0].value;
      }
      // Inicializo aC1o para altas
      // apds lo tienen disabled en template solo usan este
      // apds Nuevos solo aC1o actual y siguiente aC1o a partir de octubre
      if (!this.conDato(this.schema.ctrls.ano.value)) {
        this.schema.ctrls.ano.value = String(new Date().getFullYear());
        if (new Date().getMonth() >= 9) { // octubre
          this.schema.ctrls.ano.value = String(new Date().getFullYear() + 1);
        }
      }
      // boton importacion solo en edicion
      this.btnSet("imp_prestos", { visible: true, disabled: !this.is_edit });
      this.plantillaDisabled = !this.is_edit;
      //this.btnSet("copy",{visible:true, disabled:!this.is_edit});
      this.btnSet("pega", { visible: true, disabled: !this.is_edit });
      // apds
      // No eliminar y No edit anteriores aC1os

      this.btnSet(3, { view: true, disabled: this.app == 2 });
      this.btnSet(2, { view: true, disabled: false });
      if (Number(this.schema.ctrls.ano.value) < 2022 && this.app!=1) {
        this.btnSet(3, { view: true, disabled: true });
        this.btnSet(2, { view: true, disabled: true });
      }
    },

    //
    async get_schema_directo() {
      // Pendiente comentar cuando se utiliza esta funcion

      // ejecuto API
      let args = {
        tipo: "M",
        api: "funes_presLin_M",
        accion: "get_schema",
        fn_args: null
      };

      let apiResult = JSON.parse(
        await this.$store.dispatch("ajaxRequest", { args: args })
      );
      return apiResult;
    },

    async validate_particular() {
      this.mensajeGuardarFin='';

      if (this.schema.ctrls.tcc.comp.valueaux == "" && this.estado != "nuevo") {
        this.dialogChanges = true;
        return false;
      }
      // Chequear municipio obligatorio segun tipo de presupuesto
      if (!this.conDato(this.schema.ctrls.cia_id.value)) {
        this.$root.$alert.open("Debe introducir compaC1C-a", "error");
        return false;
      }
      if (!this.conDato(this.schema.ctrls.id_presciac.value)) {
        this.$root.$alert.open(
          "Debe introducir un tipo de prestaciC3n",
          "error"
        );
        return false;
      }
      if (
        !this.conDato(this.schema.ctrls.municipio.value) &&
        this.municipio_required.includes(
          Number(this.schema.ctrls.id_presciac.value)
        )
      ) {
        this.$root.$alert.open("Debe introducir un municipio", "error");
        return false;
      }
      // Si llega aqui es que no se necesita municipio y no hago mas comprobaciones
      if (!this.conDato(this.schema.ctrls.municipio.value)) return true;

      // Chequeo si presupuesto duplicado de la misma funeraria o si existe o tro preferente de otra funeria
      var args = {
        tipo: "fnc",
        accion: "presupuestos",
        fn_args: {
          accion: "preferente",
          id: this.$store.state[this.stName].record.id,
          ano: this.schema.ctrls.ano.value,
          fun_id: this.funeraria.id,
          cia_id: this.schema.ctrls.cia_id.value,
          id_presciac: this.schema.ctrls.id_presciac.value,
          municipio: this.schema.ctrls.municipio.value
        }
      };

      var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        return;
      }

      // Chequeo duplicado (el servidor ya tiene los tipos que no se pueden duplicar)
      if (apiResult.r[0][0] != 0) {
        this.$root.$alert.open(
          " La funeraria ya tiene presupuesto y para este tipo solo se permite uno",
          "error"
        );
        return false;
      }
      // Chequeo preferente

      if (this.schema.ctrls.preferente.value == "1" && apiResult.r[0][1] != 0) {
        this.$root.$alert.open(
          "Ya existe un preferente en: " + apiResult.r[0][1],
          "error"
        );
        return false;
      }

      // chequear APD obligatorio para determinadas centrales de sucursales y para un determinado tipo de presupuesto
      // Cheueo si la cia obliga a apd
      if (!this.apd_cias.includes(Number(this.schema.ctrls.cia_id.value)))
        return true;
      //Chequeo si el tipp de presupuesto obliga a apd
      if (!this.apd_tipos.includes(Number(this.schema.ctrls.id_presciac.value)))
        return true;

      let hijo = this.$store.state[this.stName].hijo;
      let lineaApdIndex= this.$store.state[hijo].records.findIndex(record => Number(record.prod_id) == 149);
      if (lineaApdIndex>=0) {
        if (apiResult.r[0][2]>0 && this.$store.state[hijo].records[lineaApdIndex].imp!= apiResult.r[0][2]) {
          this.$set(this.$store.state[hijo].records[lineaApdIndex], 'imp', apiResult.r[0][2]);
          this.$set(this.$store.state[hijo].records[lineaApdIndex], 'pvp', apiResult.r[0][2]);
          this.detalle_modificado(this.$store.state[hijo]);         
          this.mensajeGuardarFin= 'Modificado automC!ticamente el precio del APD';
        }
        
        return true;
      }



      // alert("aC1ado apd")
      // aC1adir APD automC!tico
      this.$store.state[hijo].records.push({
        clave: "CD",
        grup: "P",
        imp: apiResult.r[0][2],
        iva: this.schema.ctrls.iva_general.value,
        namea: "",
        orden: 99,
        prod_id: "149",
        pvp: apiResult.r[0][2],
        sp: 1,
        sup: "N",
        und: 1
      });

      this.detalle_modificado(this.$store.state[hijo]);      
      this.mensajeGuardarFin= 'APD aC1adido automC!ticamente para esta compaC1C-a y tipo de presupuesto';
      /* this.$root.$alert.open(
        "APD aC1adido automC!ticamente para esta compaC1C-a y tipo de presupuesto" +
          apiResult.r[0][1],
        "error"
      ); */
      return true;
    },

    // ACCIONES QUE SE REALIZAN CUANDO HAY UNA MODIFICACION EN EL DETALLE
    detalle_modificado(data) {
      this.pres_calculo(data.records, this.schema.ctrls, "C");
      this.calculo_claves(data.records);
    },


    guardar_fin() {      
      if (!this.mensajeGuardarFin) {
        this.$root.$alert.open("Registro guardado correctamente!", "success", 1800); 
        return true;
      }

      this.$root.$alert.open(this.mensajeGuardarFin, 'info', 5000);    
      this.mensajeGuardarFin= '';
      return true;
    },



    // Parametros particulares de este mantenimiento para la api
    ver_params() {
      return {
        tipo: "fnc",
        accion: "presupuestos",
        fn_args: { tipo: "pres", accion: "get", id: this.accionRow.id }
      };
    },
    imp_prestos() {
      if (!this.is_edit) return;
      this.componente_dinamico = "funes_pres_import_F";
      this.modal = true;
      this.disparo_funes_pres_import = !this.disparo_funes_pres_import;
    },
    async imp_prestos_extraer(evt) {
      if (evt.accion == "cerrar") this.modal = false;
      if (evt.accion != "extraer") return;
      this.modal = false;
      // this.componente_dinamico=null;
      //
      let lins = [];
      lins.push(evt.row.id);

      let args = {
        tipo: "fnc",
        accion: "presupuestos",
        fn_args: { accion: "fusionLineas", tipo: "pres", id: lins }
      };

      let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
      //pendiente: controlar error

      lins = apiResult.r[0];
      if (apiResult.sql.error == true) {
        return;
      }
      if (lins.length) this.lineas2records(lins);
      return;
    },
    lineas2records(lins) {
      if (Array.isArray(lins) && lins.length) {
        let hijo = this.$store.state[this.stName].hijo;
        this.$store.commit(
          this.$store.state[this.stName].hijo + "/data2State",
          { prop: "records", value: [...lins] }
        );
        this.detalle_modificado(this.$store.state[hijo]);
      }
    },
    copy() {
      const hijo = this.$store.state[this.stName].hijo;
      const records = JSON.parse(
        JSON.stringify(this.$store.state[hijo].records)
      );
      if (records.length == 0) {
        this.$root.$alert.open("No hay datos para copiar", "error");
        return;
      }
      this.$store.commit("data2State", {
        modulo: "datos_iniciales",
        prop: "recordsCopy",
        value: records
      });
      this.$root.$alert.open("Datos copiados", "info");
    },

    pega() {
      const hijo = this.$store.state[this.stName].hijo;
      const records = JSON.parse(
        JSON.stringify(this.$store.state.datos_iniciales.recordsCopy)
      );

      if (records.length == 0) {
        this.$root.$alert.open("No hay datos para pegar", "error");
        return;
      }
      if (records.length > 0 && this.is_edit) {
        this.$store.commit("data2State", {
          modulo: hijo,
          prop: "records",
          value: records
        });
        this.detalle_modificado(this.$store.state[hijo]);
        this.$root.$alert.open("Datos pegados", "info");
      }
    },

    // ver pdf
    async pdf() {
      if (this.record.id == 0) return;
      let args = {
        tipo: "fnc",
        accion: "presupuestos",
        fn_args: { accion: "verpdf", id: this.record.id }
      };

      let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });

      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        return;
      }

      // abro documento
      window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");
    },

    //
    changeRt(val) {
      let ivaStr=this.$store.state.datos_iniciales.rTribut[val].tipos[0].value;
      this.recalcularIva(ivaStr);
    },

    changeIvaGeneral(val) {
      this.recalcularIva(val);
    },

    recalcularIva(iva) {
      this.schema.ctrls.iva_general.value= iva;
      let hijo = this.$store.state[this.stName].hijo;
      let records=JSON.parse(JSON.stringify(this.$store.state[hijo].records));
      
      records.forEach(item => { 
                          if(item.sup=='N') item.iva=iva;
                          if (this.rTributName=="IVA" && this.iva10.includes(Number(item.prod_id))){
                              item.iva="10.0"
                          }
      });
      this.$store.commit('data2State', { modulo:hijo, prop:'records', value:records});
      this.detalle_modificado(this.$store.state[hijo]);
    },





    changePlantilla() {
      if (this.plantillaValue == "0") return;
      this.$root.$alert
        .open("B?Importar  plantilla?", "confirm")
        .then(r => this.changePlantilla_respuesta(r));
    },
    async changePlantilla_respuesta() {
      let args = {
        tipo: "fnc",
        accion: "presupuestos",
        fn_args: {
          accion: "plantilla",
          rt: this.schema.ctrls.rt.value,
          id: this.plantillaValue
        }
      };
      this.plantillaValue = "0";
      let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });

      if (apiResult.sql.error == true) {
        return;
      }
      this.lineas2records(apiResult.r[0]);
    },

    conDato(i) {
      if (i == "0" || i == "" || typeof i === "undefined" || i == null)
        return false;
      return true;
    },


    
  },

  computed: {
    is_mxEdit() {
      var hijo = this.$store.state[this.stName].hijo;
      if (!hijo) return false;

      var nieto = this.$store.state[hijo].hijo;

      if (!nieto) return false;
      // revisar de antonio if raro
      return this.$store.state[nieto].estado === "editar" ||
        this.$store.state[nieto].estado === "nuevo"
        ? true
        : false;
    },
    // Devuelvo record de funeraria a la que pertenece
    funeraria() {
      var padre = this.$store.state[this.stName].padre;
      var abuelo = this.$store.state[padre].padre;
      return this.$store.state[abuelo].record;
    },

    get_ivas() {
      if (!this.$store.state.datos_iniciales) return [];
      if (this.schema.ctrls.rt.value) {
        return this.$store.state.datos_iniciales.rTribut[
          this.schema.ctrls.rt.value
        ].tipos;
      }

      return [];
    },
    rTributName() {
      if (this.schema.ctrls.rt.value != "") {
        return this.$store.state.datos_iniciales.rTribut[
          Number(this.schema.ctrls.rt.value)
        ].name;
      }
      return "";
    }
  },

  watch: {
    miStHijo() {
      console.log("HIJO WATCH FUNES::: ", this.miStHijo);
      if (!this.miStHijo) return;
      if (this.miStHijo.name != this.stNameHijo) {
        this.$root.$alert.open(
          "Llame a informC!tica al telC)fono 666564931 indicando lo siguiente: HIJO= " +
            this.miStHijo.name,
          "info"
        );
        this.$store.commit("data2State", {
          modulo: this.stName,
          prop: "hijo",
          value: this.stNameHijo
        });
      }
    }
  }
};
</script>
