export var calculo_claves = {
  data() {
    return {
      titulo_claves: ["CAPITALES", "SERVICIO", "DIFERENCIAS"],
      Aclaves: [
        { grupo: "Servicio", claves: ["XJ", "FR", "CD"], field: "servpm", imp: [0, 0, 0] },
        { grupo: "Nicho", claves: ["YA"], field: "servnicho", imp: [0, 0, 0] },
        { grupo: "Incinerac.", claves: ["YJ"], field: "servincinera", imp: [0, 0, 0] },
        { grupo: "Tanatorio", claves: ["YC"], field: "servtana", imp: [0, 0, 0] },
        { grupo: "Lapida", claves: ["YB"], field: "servlapida", imp: [0, 0, 0] },
        { grupo: "Recog. Jud.", claves: ["YW"], imp: [0, 0, 0] },
        { grupo: "Traslados", claves: ["XP", "YH", "XY"], imp: [0, 0, 0] }
      ],
      // descripcion de imp:[0,0,0]=imp:[servicio, capitales, diferencia]

      // clavesAc Array de grupos de claves
      clavesAc: [],
      clavesCapitales:{}, // se corresponden con el field del array de claves
      clavesTotal: { servicio: 0, capital: 0, dif: 0, pmortem: 0, traslado: 0 },
     
    };
  },

  methods: {
    
    calculo_claves(records, capitales = {}) {
      console.log("capitales", capitales)
      // let capitales={servpm:this.expte.record.servpm,
      //   servnicho:this.expte.record.servnicho,
      //   servincinera:this.expte.record.servincinera,
      //   servlapida:this.expte.record.servlapida,
      //   servtana:this.expte.record.servtana,
      //   };
      //
      // inicializo acumulados de grupos y sus capitales que estan en imp[]
      // imp[0]=acumulado servicio, imp[1]=capital , imp[2] diferencia
      this.clavesAc = JSON.parse(JSON.stringify(this.Aclaves)); 
      // inicializo totales
      this.clavesTotal= { servicio: 0, capital: 0, dif: 0, pmortem: 0, traslado: 0, complemento:0, complementoDif:0 };
      // recorro records de detalle y acumulo el servicio en cada grupo en imp[0]
      records.forEach(r => this.acumula(r));
        // meto capitales en cada grupo y calculo la diferencia del servicio imp[0] con capital imp[1]
        Object.keys(capitales).forEach(field=>this.clavesAc.map(grupo => {
              if (grupo.field == field) {
                  grupo.imp[1] = Number(capitales[field])
                  grupo.imp[2]=grupo.imp[1]-grupo.imp[0]
              }
              return grupo
            }))
        //
        this.clavesTotal.servicio = Number(this.clavesTotal.servicio).toFixed(2);
        this.clavesTotal.capital= Object.keys(capitales).reduce((ac,item)=>ac+Number(capitales[item]),0);
        this.clavesTotal.dif = (Number(this.clavesTotal.capital)-Number(this.clavesTotal.servicio)).toFixed(2)
       //
        this.clavesTotal.complemento=Number(capitales.servcomplemento);
        this.clavesTotal.complementoDif=Number(capitales.servcomplemento)+ this.clavesTotal.capital-this.clavesTotal.servicio;
        console.log("capitales 2", this.clavesTotal)
    },
    acumula(r) {
      let importe=Number(r.imp)*(1+Number(r.iva)/100);
      // Acumula en el grupo que contiene la clave
      this.clavesAc.forEach(grupo => {
        if (grupo.claves.includes(r.clave)) {
          grupo.imp[0] += importe;
        }
      })
        // Acumula postmortem o traslado
        r.grup == "P"
          ? (this.clavesTotal.pmortem += importe)
          : (this.clavesTotal.traslado += importe);

         // acumulo en servicio
        // No se incluye traslado ni recogida judicial
        this.clavesTotal.servicio += (this.clavesAc[5].claves.includes(r.clave) || this.clavesAc[6].claves.includes(r.clave))
          ? 0
          : importe
        
      }
  }
};
